import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = { kind: String, selectType: Boolean }

  connect() {
    const root = this.element;
    const input = this.inputTarget;
    const form = $(input).closest('form')[0]
    const inPopup = this.kindValue == "post_type_popup";

    if (input.value && !this.selectTypeValue) {
      $('input[type=checkbox][value=' + input.value + ']').prop('checked', true);
    }

    $('input[type=checkbox]', root).on('click', function () {
      $('#client-select-type').prop('checked', false);
      $('input:checked', root).each(function () {
        this.checked = false;
        // $(this.parentElement).removeClass('active');
      });
      this.checked = true;
      input.value = this.value;
      // $(this.parentElement).addClass('active');
    })

    const customSocialInput = document.getElementById("other-social");;
    const checkboxes = $('input[type=checkbox]', root);
    const value = $(customSocialInput).val();
    if (value == ''){
      checkboxes.prop('disabled', true);
    }

    $(customSocialInput).on("input", () => {
      const value = $(customSocialInput).val();
      if (/^[A-Za-zÄÖÜäöüß0-9]{3,16}$/.test(value)) {
        checkboxes.prop('disabled', false);
        $(customSocialInput).removeClass("is-invalid");
      } else {
        checkboxes.prop('disabled', true);
        $(customSocialInput).addClass("is-invalid");
      }
    });


  }
}
