import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["selector"]
  static values = { taskId: Number, kind: String }

  connect() {
    if (this.kindValue.length === 0) {
      this.kindValue = 'photo'
    }
  }

  changeClientSelect() {
    $('.form-check-type').prop('checked', false);
    // $('.form-check-type').parent().removeClass('active');
    if (!$('#client-select-type').is(':checked')) {
      $('input[type=checkbox][value=' + this.kindValue + ']').prop('checked', true);
      // $('input[type=checkbox][value=' + this.kindValue + ']').parent().addClass('active');
    }
  }
}